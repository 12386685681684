/* Copyright Levelise Ltd 2020-2025 */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from "react-router-dom";
import cx from 'classnames';
import Select from 'react-select';
import FleetService from '../../services/fleet-service';
import AdminContext from '../../contexts/AdminContext';
import { SpecLabel, SpecInput, Button } from '../Form/index';
import { customStyles } from './customStyles';
import {
    OPERATING_MODE,
    BATTERY_LOCATION,
    DNO,
    GSP_GROUP,
    GSP_GROUP_ID,
    GSP_GROUP_ID_NAME,
    SETTLEMENT_METHOD,
    DRU_NETWORK_CONNECTION_TYPE,
    AUS_GRID_CONNECTION_TYPE
} from '../../utils/specification';
import { timezones, ukTimezones, ausTimezones } from '../../utils/timezones';
import FleetContext from '../../contexts/FleetContext';


const SpecificationFrom = () => {
    const edit = 'edit', create = 'create', assign = 'assign', facility_option = 'facility_name', dru_option = 'dru_id',
        uk_g83_g98 = 'G83 / G98', uk_g59_g99 = 'G59 / G99', as_4777 = 'AS4777', _tz = 'Europe/London';
    const location = useLocation();
    const adminContext = useContext(AdminContext);
    const fleetContext = useContext(FleetContext);
    const [userFacilities, setUserFacilities] = useState([]);
    const [currentOperatingMode, setCurrentOperatingMode] = useState(null);

    const [hasRequired, setHasRequired] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [dru, setDru] = useState('');
    const [facilityMessage, setFacilityMessage] = useState('');
    const [specificationMessage, setSpecificationMessage] = useState('');
    const [assignFacilityMessage, setAssignFacilityMessage] = useState('');
    const [operatingModeMessage, setOperatingModeMessage] = useState('');
    const [error, setError] = useState(null);

    const [connectionType, setConnectionType] = useState('');
    const [formType, setFormType] = useState(create);
    const [operatingMode, setOperatingMode] = useState(null);
    const [facilities, setFacilities] = useState([]);
    const [druIds, setDruIds] = useState([]);
    const [editOption, setEditOption] = useState(facility_option);
    const [selected, setSelected] = useState('');
    const [facility, setFacility] = useState('');
    const [pvNetCapacity, setPvNetCapacity] = useState('');
    const [postCode, setPostCode] = useState('');
    const [gspGroup, setGspGroup] = useState('');
    const [dno, setDno] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [lineLossFactor, setLineLossFactor] = useState('');
    const [settlementMethod, setSettlementMethod] = useState('');
    const [has3phase, setHas3phase] = useState(false);
    const [hasGridMeter, setHasGridMeter] = useState(false);
    const [drAllowed, setDrAllowed] = useState(true);
    const [dcAllowed, setDcAllowed] = useState(true);
    const [ports, setPorts] = useState('');
    const [supplyPhases, setSupplyPhases] = useState('');
    const [importLimit, setImportLimit] = useState('');
    const [exportLimit, setExportLimit] = useState('');
    const [batterySystem, setBatterySystem] = useState('');
    const [druNetwork, setDruNetwork] = useState('');
    const [hotWaterTank, setHotWaterTank] = useState('');
    const [batteryLocation, setBatteryLocation] = useState('');
    const [chargePoint, setChargePoint] = useState('');

    const [invertPv, setInvertPv] = useState(false);
    const [invertGrid, setInvertGrid] = useState(false);
    const [invertDruInverter, setInvertDruInverter] = useState(false);
    const [invertDruGrid, setInvertDruGrid] = useState(false);
    const [invertDruHw, setInvertDruHw] = useState(false);

    const handleOnFormChange = async (type) => {
        if (type === create || formType === create) {
            clearStates(type === create);
            setSelected('');
        } else {
            setAssignFacilityMessage('');
            setOperatingModeMessage('');
        }
        setFormType(type);
    }

    const handleOnChangeEditOption = (type) => {
        let _selected = '';
        if (editOption !== type) {
            if (!!selected && !!selected.value) {
                const _find = type === facility_option ? 'druId' : 'name';
                const _key = type === facility_option ? 'name' : 'druId';
                const _facility = userFacilities.find(obj => obj[_find] === selected.value)

                if (_facility) {
                    _selected = { value: _facility[_key], label: _facility[_key] }
                }
            }
        }

        if (_selected === '') {
            clearStates();
        }

        setSelected(_selected);
        setEditOption(type);
    }

    const handleSetFacility = (facility) => {
        setSelected(facility);

        if (editOption === facility_option) {
            setFacility(facility.value);
        }

        if (editOption === dru_option) {
            FleetService.getFacilityByDruId(facility.value)
                .then(res => setFacility(res.name))
                .catch(console.info);
        }
    }

    const handleSetPvNetCapacity = (pvNetCapacity) => {
        setPvNetCapacity(pvNetCapacity)
        if (pvNetCapacity !== '') {
            const connectionTypeForPvNetCapacity = parseInt(pvNetCapacity) <= 3680 ? uk_g83_g98 : uk_g59_g99;
            if (connectionTypeForPvNetCapacity != connectionType) setConnectionType(connectionTypeForPvNetCapacity);
        }
    }

    const getGspGroups = () => {
        let gspGroupOptions = GSP_GROUP;
        if (!!dno && !!dno.value) {
            const value = parseInt(dno.value);
            if (10 <= value && value <= 23) {
                gspGroupOptions = { [GSP_GROUP_ID_NAME[value]]: GSP_GROUP[GSP_GROUP_ID_NAME[value]] };
            }
        }

        if (!!gspGroup && !gspGroupOptions.hasOwnProperty(gspGroup.value)) {
            setGspGroup('');
        }

        return gspGroupOptions;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasRequired(null);
        setValidationError('');
        setFacilityMessage('');
        setSpecificationMessage('');
        setAssignFacilityMessage('');
        setOperatingModeMessage('')
        setError(null);

        if (formType === create) {
            createFacility();
        }

        if (formType === edit) {
            editFacility();
        }

        if (formType === assign) {
            assignFacility();
        }
    }

    const handleReset = (e) => {
        e.preventDefault();
        clearStates();
    }

    const createFacility = async () => {
        const name = !!facility ? facility : 'FACILITY';
        setFacility(name);

        const inputs = [pvNetCapacity, postCode, dno.value, timeZone.value, lineLossFactor, settlementMethod.value,
            ports.value, supplyPhases.value];
        for (let i = 0; i < inputs.length; i++) {
            if (!String(inputs[i])) {
                setHasRequired(true);
                return;
            }
        }

        try {
            validateInputs();
        } catch (e) {
            setHasRequired(true);
            setValidationError(e.message);
            return;
        }

        try {
            const druId = await Promise.resolve(getNewDru()).then(res => res.id.toString());
            await Promise.resolve(addNewFacility(name, druId));
        } catch (e) {
            setError(e);
        }
    }

    const editFacility = async () => {
        const inputs = [facility, pvNetCapacity, postCode, dno.value, timeZone.value, lineLossFactor,
            settlementMethod.value, ports.value, supplyPhases.value];

        for (let i = 0; i < inputs.length; i++) {
            if (!String(inputs[i])) {
                setHasRequired(true);
                return;
            }
        }

        try {
            validateInputs();
        } catch (e) {
            setHasRequired(true);
            setValidationError(e.message);
            return;
        }

        try {
            await Promise.resolve(setSpecification(facility));
        } catch (e) {
            setError(e);
        }
    }

    const assignFacility = async () => {
        if (!facility) {
            setHasRequired(true);
            return;
        }

        try {
            if (operatingMode?.value && operatingMode.label !== currentOperatingMode) {
                await Promise.resolve(setOperatingModeToFacility(facility));
            }
        } catch (e) {
            setError(e);
        }
    }

    const getNewDru = async () => {
        return await FleetService.getNewDru()
            .then(res => {
                setDru(res);
                return res;
            })
            .catch(error => {
                adminContext.setError(error);
                throw error.message;
            });
    }

    const addNewFacility = async (name, druId) => {
        const facility = {
            'name': (`${name}-${druId.slice(0, 4)}-${druId.slice(4)}`).toUpperCase(),
            'druId': parseInt(druId),
            'pvNetCapacityW': parseInt(pvNetCapacity),
            'postcode': postCode,
            'gridConnectionId': getGridConnectionTypeId(),
            'timezone': !!timeZone ? timeZone.value : null,
            'lineLossFactor': lineLossFactor,
            'settlementMethodId': !!settlementMethod ? parseInt(settlementMethod.value) : null,
            'has3PhaseMetering': has3phase,
            'hasGridMeter': hasGridMeter,
            'drAllowed': drAllowed,
            'dataCollectionAllowed': dcAllowed,
            'supplyPhases': parseInt(supplyPhases.value),
            'rs485': !!ports.value ? parseInt(ports.value) : null,
            'exportLimitW': !!exportLimit ? parseInt(exportLimit) : null,
            'batterySystemId': !!batterySystem && batterySystem.value !== 0 ? parseInt(batterySystem.value) : null,
            'druNetworkId': !!druNetwork && druNetwork.value !== 0 ? parseInt(druNetwork.value) : null,
            'hotWaterTankId': !!hotWaterTank && hotWaterTank.value !== 0 ? parseInt(hotWaterTank.value) : null,
            'batteryLocationId': !!batteryLocation && batteryLocation.value !== 0 ? parseInt(batteryLocation.value) : null,
            'evChargePointId': !!chargePoint ? parseInt(chargePoint) : null,
            'invertPv': invertPv,
            'invertGrid': invertGrid,
            'invertDruInverter': invertDruInverter,
            'invertDruGrid': invertDruGrid,
            'invertDruHotWater': invertDruHw
        };

        Object.keys(facility).forEach(key => {
            if (facility[key] === null || facility[key] === '')
                delete facility[key];
        })

        await FleetService.addNewFacility(facility)
            .then(() => {
                setFacilityMessage(facility['name']);
            })
            .catch(error => {
                adminContext.setError(error);
                throw error.message;
            });
    }

    const setSpecification = async (facilityName) => {
        let specifications = {
            'pvNetCapacityW': parseInt(pvNetCapacity),
            'postcode': postCode,
            'gridConnectionId': getGridConnectionTypeId(),
            'timezone': !!timeZone ? timeZone.value : null,
            'lineLossFactor': lineLossFactor,
            'settlementMethodId': !!settlementMethod ? parseInt(settlementMethod.value) : null,
            'has3PhaseMetering': has3phase,
            'hasGridMeter': hasGridMeter,
            'drAllowed': drAllowed,
            'dataCollectionAllowed': dcAllowed,
            'supplyPhases': parseInt(supplyPhases.value),
            'rs485': !!ports.value ? parseInt(ports.value) : null,
            'importLimitW': !!importLimit ? parseInt(importLimit) : null,
            'exportLimitW': !!exportLimit ? parseInt(exportLimit) : null,
            'batterySystemId': !!batterySystem && batterySystem.value !== 0 ? parseInt(batterySystem.value) : null,
            'druNetworkId': !!druNetwork && druNetwork.value !== 0 ? parseInt(druNetwork.value) : null,
            'hotWaterTankId': !!hotWaterTank && hotWaterTank.value !== 0 ? parseInt(hotWaterTank.value) : null,
            'batteryLocationId': !!batteryLocation && batteryLocation.value !== 0 ? parseInt(batteryLocation.value) : null,
            'evChargePointId': !!chargePoint ? parseInt(chargePoint) : null,
            'invertPv': invertPv,
            'invertGrid': invertGrid,
            'invertDruInverter': invertDruInverter,
            'invertDruGrid': invertDruGrid,
            'invertDruHotWater': invertDruHw
        };

        Object.keys(specifications).forEach(key => {
            if (specifications[key] === null || specifications[key] === '')
                delete specifications[key];
        })

        await FleetService.setFacility(facilityName, specifications)
            .then(() => {
                setSpecificationMessage(`Facility ${facilityName} has been updated.`);
            })
            .catch(error => {
                adminContext.setError(error);
                throw error.message;
            });
    }

    const getGridConnectionTypeId = () => {
        const value = parseInt(dno.value)
        if (timeZone.value.toLowerCase().includes('london')) {
            if (connectionType === uk_g59_g99) {
                return !!gspGroup.value && value > 23 ? GSP_GROUP_ID[gspGroup.value][0] * 100 + value : value < 10
                    ? 2 : 5900 + value;
            }
            if (connectionType === uk_g83_g98) {
                return !!gspGroup.value && value > 23 ? GSP_GROUP_ID[gspGroup.value][1] * 100 + value : value < 10
                    ? 1 : 8300 + value;
            }
        }
        if (timeZone.value.toLowerCase().includes('australia')) {
            if (connectionType === as_4777) {
                return 4700 + value;
            }
        }
        return null;
    }


    const setOperatingModeToFacility = async (facilityName) => {
        return await FleetService.setOperatingMode(facilityName, operatingMode.value)
            .then(res => {
                let message = `Set operating mode to ${operatingMode.value}.`;
                setOperatingModeMessage(message);
                setCurrentOperatingMode(operatingMode.label);
                return res;
            })
            .catch(error => {
                adminContext.setError(error);
                throw error.message;
            });
    }

    const validateInputs = () => {
        const regexUk = /^[A-Z]{1,2}[0-9][A-Z0-9]?\s{1}?[0-9]{1}$/;
        const regexAus = /[0-9]{4}/;
        if (ukTimezones.includes(timeZone.value) && !regexUk.test(postCode)) {
            setPostCode('');
            throw new Error('Post code is not valid.');
        }
        if (ausTimezones.includes(timeZone.value) && !regexAus.test(postCode)) {
            setPostCode('');
            throw new Error('Post code is not valid.');
        }
        if (isNaN(pvNetCapacity) || parseInt(Number(pvNetCapacity)) !== Number(pvNetCapacity)) {
            setPvNetCapacity('');
            throw new Error('Nominal PW power must be integer.');
        }
        if (lineLossFactor.length !== 3) {
            setLineLossFactor('');
            throw new Error('Line loss factor is not valid.');
        }
        if (importLimit !== '' && (isNaN(importLimit) || parseInt(Number(importLimit)) !== Number(importLimit))) {
            setImportLimit('');
            throw new Error('Import limit must be integer.');
        }
        if (exportLimit !== '' && (isNaN(exportLimit) || parseInt(Number(exportLimit)) !== Number(exportLimit))) {
            setExportLimit('');
            throw new Error('Export limit must be integer.');
        }
        if (chargePoint !== '' && (isNaN(chargePoint) || parseInt(Number(chargePoint)) !== Number(chargePoint))) {
            setChargePoint('');
            throw new Error('EV charge point ID must be integer.');
        }
    }

    const getOptions = (systemTypes, sort = false) => {
        if (!!systemTypes) {
            const none = '-- None --';
            let options = [];
            if (Array.isArray(systemTypes)) {
                options = systemTypes.map(type => {
                    return { value: type !== none ? type : '', label: type };
                })
            } else {
                options = Object.keys(systemTypes).map(type => {
                    if (sort) {
                        return {
                            value: systemTypes[type] !== none ? type : '',
                            label: systemTypes[type] !== none ? `${systemTypes[type]} (${type})` : systemTypes[type]
                        };
                    }
                    return {
                        value: systemTypes[type] !== none ? type : '',
                        label: systemTypes[type]
                    };
                })
            }

            if (sort)
                return options.sort((a, b) => a.label === none || a.label > b.label ? 1 : -1)
            return options;
        }
    }

    const getDnos = () => {
        if (!!timeZone) {
            if (timeZone.value.toLowerCase().includes('london')) {
                return DNO;
            }

            if (timeZone.value.toLowerCase().includes('australia')) {
                return AUS_GRID_CONNECTION_TYPE;
            }
        }

        return '';
    }

    const getPorts = () => {
        return { 1: '1', 2: '2' };
    }

    const getSupplyPhase = () => {
        return { 0: '0', 1: '1', 2: '2', 3: '3' };
    }

    const disableCheckbox = (zone) => {
        if (!!timeZone) {
            return timeZone.value.toLowerCase().includes(zone) ? false : true;
        }
        return false;
    }

    const clearStates = (setTz = false) => {
        const tz = setTz ? { value: fleetContext.timezone || _tz, label: fleetContext.timezone || _tz } : '';
        const port = formType === create ? { value: 1, label: getPorts()[1] } : '';
        const supply = formType === create ? { value: 1, label: getPorts()[1] } : '';
        const _drAllowed = setTz;
        const _dcAllowed = setTz;

        setCurrentOperatingMode(null)
        setHasRequired(null);
        setValidationError('');
        setHasRequired('');
        setValidationError('');
        setFacilityMessage('');
        setSpecificationMessage('');
        setAssignFacilityMessage('');
        setOperatingModeMessage('');
        setError(null);
        setOperatingMode('');
        setSelected('');
        setFacility('');
        setPvNetCapacity('');
        setTimeZone(tz);
        setPostCode('');
        setConnectionType('');
        setDno('');
        setGspGroup('');
        setLineLossFactor('');
        setSettlementMethod('');
        setHas3phase(false);
        setHasGridMeter(false);
        setDrAllowed(_drAllowed);
        setDcAllowed(_dcAllowed);
        setPorts(port);
        setSupplyPhases(supply);
        setImportLimit('');
        setExportLimit('');
        setBatterySystem('');
        setDruNetwork('');
        setHotWaterTank('');
        setBatteryLocation('');
        setChargePoint('');
        setInvertPv(false);
        setInvertGrid(false);
        setInvertDruInverter(false);
        setInvertDruGrid(false);
        setInvertDruHw(false);
    }

    const updateState = (specification) => {
        const strDefault = { value: '', label: '' };
        const noneDefault = { value: '', label: '-- None --' };
        const _timeZone = getValueWithIdName(specification, 'timezone');
        const _nominalPv = getValueWithKey(specification, 'pvNetCapacityW');
        const _postCode = getValueWithKey(specification, 'postcode');
        const _connectionType = getValueWithKey(specification, 'gridConnection', 'connectionType');
        const _dnos = _timeZone.value.includes('London') ? DNO : _timeZone.value.includes('Australia') ? AUS_GRID_CONNECTION_TYPE : {};
        const _dno = getValueWithIdName(specification, 'gridConnection', 'dno', 'dno', noneDefault, _dnos);
        const _gsp_group = getValueWithIdName(specification, 'gspGroup', '', '', strDefault, GSP_GROUP);
        const _lineLossFactor = getValueWithKey(specification, 'lineLossFactor');
        const _settlementMethod = getSettlementValue(specification, strDefault);
        const _has3phase = getValueWithKey(specification, 'has3PhaseMetering', '', false);
        const _hasGridMeter = getValueWithKey(specification, 'hasGridMeter', '', false);
        const _drAllowed = getValueWithKey(specification, 'drAllowed', '', false);
        const _dcAllowed = getValueWithKey(specification, 'dataCollectionAllowed', '', false);
        const _ports = getValueWithIdName(specification, 'rs485', '', '', strDefault, getPorts());
        const _supplyPhases = getValueWithIdName(specification, 'supplyPhases', '', '', strDefault, getSupplyPhase());
        const _importLimit = getValueWithKey(specification, 'importLimitW');
        const _exportLimit = getValueWithKey(specification, 'exportLimitW');
        const _batterySystem = getValueWithIdName(specification, 'batterySystem', 'id', 'name', noneDefault);
        const _druNetwork = getValueWithIdName(specification, 'druNetwork', 'id', 'name', noneDefault);
        const _hotWaterTank = getValueWithIdName(specification, 'hotWaterTank', 'id', 'name', noneDefault);
        const _batteryLocation = getValueWithIdName(specification, 'batteryLocation', 'id', 'name', noneDefault);
        const _chargePoint = getValueWithKey(specification, 'evChargePointId');
        const _invertPv = getValueWithKey(specification, 'invertPv', '', false);
        const _invertGrid = getValueWithKey(specification, 'invertGrid', '', false);
        const _invertDruInverter = getValueWithKey(specification, 'invertDruInverter', '', false);
        const _invertDruGrid = getValueWithKey(specification, 'invertDruGrid', '', false);
        const _invertDruHw = getValueWithKey(specification, 'invertDruHotWater', '', false);

        setTimeZone(_timeZone);
        setPvNetCapacity(_nominalPv);
        setPostCode(_postCode);
        setConnectionType(_connectionType);
        setDno(_dno);
        setGspGroup(_gsp_group);
        setLineLossFactor(_lineLossFactor);
        setSettlementMethod(_settlementMethod);
        setHas3phase(_has3phase);
        setHasGridMeter(_hasGridMeter);
        setDrAllowed(_drAllowed);
        setDcAllowed(_dcAllowed);
        setPorts(_ports);
        setSupplyPhases(_supplyPhases);
        setImportLimit(_importLimit);
        setExportLimit(_exportLimit);
        setBatterySystem(_batterySystem);
        setDruNetwork(_druNetwork);
        setHotWaterTank(_hotWaterTank);
        setBatteryLocation(_batteryLocation);
        setChargePoint(_chargePoint);
        setInvertPv(_invertPv);
        setInvertGrid(_invertGrid);
        setInvertDruInverter(_invertDruInverter);
        setInvertDruGrid(_invertDruGrid);
        setInvertDruHw(_invertDruHw);
    }

    const getValueWithIdName = (specification, key, id = '', name = '', defaultVal = '', data = null) => {
        if (!id && !name) {
            if (specification.hasOwnProperty(key))
                return {
                    value: specification[key],
                    label: data !== null ? data[specification[key]] : specification[key]
                };
            return defaultVal;
        }

        if (specification.hasOwnProperty(key) && specification[key].hasOwnProperty(id)) {
            let value = specification[key][id];
            let label = data !== null ? data[specification[key][name]] : specification[key][name];
            if (id === 'id' && name === 'name') {
                label = `${label} (${specification[key][id]})`
            }

            return { value, label };
        }

        return defaultVal;
    }

    const getValueWithKey = (specification, key, subKey = '', defaultVal = '') => {
        if (!!subKey)
            return specification.hasOwnProperty(key) ? specification[key][subKey] : defaultVal;
        return specification.hasOwnProperty(key) ? specification[key] : defaultVal;
    }

    const getSettlementValue = (specification, defaultVal) => {
        if (specification.hasOwnProperty('settlementMethod')) {
            const settlementMethod = specification['settlementMethod'];
            let value = null, label = null;
            if (typeof settlementMethod === 'string' || settlementMethod instanceof String) {
                // TODO: Remove this condition when "/fleet/facility/<facility_name>" endpoint
                //       switches return value for settlementMethod from description to id.
                value = Object.keys(SETTLEMENT_METHOD).find(key => SETTLEMENT_METHOD[key].includes(settlementMethod));
                label = `${settlementMethod} (${value})`;
            } else {
                value = settlementMethod;
                label = SETTLEMENT_METHOD.hasOwnProperty(settlementMethod) ? SETTLEMENT_METHOD[settlementMethod] : null;
            }

            if (!!value && !!label)
                return { value, label };
        }

        return defaultVal;
    }

    const handleFormUpdate = () => {
        if (formType === edit || formType === assign) {
            FleetService.getFacilities()
                .then(res => {
                    const facilityNames = res.map(facility => facility['name']);
                    setUserFacilities(res);
                    setFacilities(facilityNames);
                    checkFacilityPath(facilityNames);
                })
                .catch(console.info);

            FleetService.getDruList()
                .then(setDruIds)
                .catch(console.info);
        }
    }

    const checkFacilityPath = (facilityNames) => {
        if (!!location.state) {
            if (location.state.hasOwnProperty('prevPath') && location.state.prevPath.includes('/facility/')) {
                const _facility = location.state.prevPath.slice(10);
                if (facilityNames.includes(_facility) && editOption === facility_option) {
                    handleSetFacility({ value: _facility, label: _facility });
                    setFacility(_facility);
                    location.state = undefined;
                }
            }
        }
    }

    const handleFacilityUpdate = () => {
        if ((formType === edit || formType === assign) && !!facility && facilities.includes(facility)) {
            FleetService.getFacility(facility)
                .then(updateState)
                .catch(error => {
                    if (error.status === 404) {
                        setSpecificationMessage(error.message);
                    }
                });

            FleetService.getOperatingMode(facility)
                .then(res => {
                    const label = Object.values(OPERATING_MODE).find(val => val === res.description);
                    setCurrentOperatingMode(res.description);
                    if (!!label) {
                        const value = Object.keys(OPERATING_MODE).find(key => OPERATING_MODE[key] === label);
                        setOperatingMode({ value: value, label: label })
                    } else {
                        setOperatingMode('');
                    }
                })
                .catch(error => {
                    if (error.status === 404) {
                        setAssignFacilityMessage(error.message);
                    }
                });
        }
    }

    const handleSetTimezone = (tz) => {
        setTimeZone(tz)
        if (!!tz) {
            const zone = tz.value.toLowerCase()
            if (zone.includes('london')) {
                if (connectionType !== uk_g59_g99 && connectionType !== uk_g83_g98) {
                    setDno('');
                    setGspGroup('')
                    setConnectionType(uk_g83_g98);
                }
            } else if (zone.includes('australia')) {
                setDno('');
                setGspGroup('')
                setConnectionType(as_4777);
            } else {
                setDno('');
                setGspGroup('')
                setConnectionType('');
            }
        }
    }

    useEffect(() => {
        handleFormUpdate();
    }, [formType])

    useEffect(() => {
        handleFacilityUpdate();
    }, [facility])

    useEffect(() => {
        const state = location.state;
        if (!!state && state.hasOwnProperty('prevPath') && state.prevPath.includes('/facility/')) {
            setFormType(edit);
        } else {
            clearStates(true);
        }
    }, [])

    const formTypeSection = () => {
        return (
            <div className="form-type" >
                <div className="form-left">Form Type</div>
                <div className="form-right" onChange={(e) => handleOnFormChange(e.target.value)}>
                    <SpecInput
                        id="create-type"
                        type="radio"
                        name="create"
                        onChange={() => { }}
                        checked={formType === create}
                        value={create} />
                    <SpecLabel id="create-label" htmlFor="create-type">Create</SpecLabel>
                    <SpecInput
                        id="edit-type"
                        type="radio"
                        name="edit"
                        onChange={() => { }}
                        checked={formType === edit}
                        value={edit} />
                    <SpecLabel id="edit-label" htmlFor="edit-type">Edit</SpecLabel>
                    <SpecInput
                        id="assign-type"
                        type="radio"
                        name="assign"
                        onChange={() => { }}
                        checked={formType === assign}
                        value={assign} />
                    <SpecLabel id="assign-label" htmlFor="assign-type">Assign</SpecLabel>
                </div>
            </div>
        )
    }

    const facilityName = () => {
        return (
            <div className="facility-name">
                <div className={cx("form-left", (formType === edit || formType === assign) ? "facility-option" : '')}>
                    {(formType === edit || formType === assign) && <span className="required">* </span>}
                    Facility
                </div>
                {formType === create &&
                    <SpecInput
                        id="name"
                        type="text"
                        name="name"
                        className={cx("form-right", hasRequired && !facility ? 'required-field' : '')}
                        value={facility}
                        onChange={(e) => setFacility(e.target.value.toUpperCase())}
                        placeholder="Name" />
                }
                {(formType === edit || formType === assign) &&
                    <div>
                        <div className="edit-options" onChange={(e) => handleOnChangeEditOption(e.target.value)}>
                            <SpecInput
                                type="checkbox"
                                id="facility-name"
                                name="edit-option"
                                className="edit-option"
                                onChange={() => { }}
                                checked={editOption === facility_option}
                                value={facility_option} />
                            <SpecLabel className="edit-option-label" htmlFor="facility-name">Name</SpecLabel>
                            <SpecInput
                                type="checkbox"
                                id="dru-id"
                                name="edit-option"
                                className="edit-option"
                                onChange={() => { }}
                                checked={editOption === dru_option}
                                value={dru_option} />
                            <SpecLabel className="edit-option-label" htmlFor="dru-id">DRU ID</SpecLabel>
                        </div>
                        <Select
                            id="name"
                            name="name"
                            className={cx("form-right", 'required-field')}
                            styles={customStyles(facility, hasRequired)}
                            onChange={handleSetFacility}
                            placeholder={`-- Select ${editOption === facility_option ? 'facility name' : 'DRU ID'} --`}
                            value={selected}
                            options={getOptions(editOption === facility_option ? facilities : druIds)} />
                    </div>
                }
            </div>
        )
    }

    const specifications = () => {
        if (formType === create || formType === edit) {
            return (
                <div className="facility-specification">
                    <div className="time-zone">
                        <div className="form-left"><span className="required">* </span>Time Zone</div>
                        <Select
                            id="timeZone"
                            name="timeZone"
                            className="form-right"
                            styles={customStyles(timeZone.label, hasRequired)}
                            value={timeZone}
                            onChange={handleSetTimezone}
                            placeholder="-- Select time zone --"
                            options={getOptions(timezones)}
                        />
                    </div>
                    <div className="post-code">
                        <div className="form-left"><span className="required">* </span>Postcode</div>
                        <SpecInput
                            id="postCode"
                            type="text"
                            name="postCode"
                            className={cx("form-right", hasRequired && !postCode ? 'required-field' : '')}
                            value={postCode}
                            onChange={(e) => setPostCode(e.target.value.toUpperCase())}
                            placeholder="Postcode sector" />
                    </div>
                    <div className="nominal-pv">
                        <div className="form-left"><span className="required">* </span>PV Net Capacity</div>
                        <SpecInput
                            id="nominalPv"
                            type="number"
                            name="nominalPv"
                            className={cx("form-right", hasRequired && !pvNetCapacity ? 'required-field' : '')}
                            value={pvNetCapacity}
                            onChange={(e) => handleSetPvNetCapacity(e.target.value)}
                            placeholder="PV net capacity in W" />
                    </div>
                    <div className="grid-connection">
                        <div className="form-left dno-option"><span className="required">* </span>DNO</div>
                        <div>
                            <div className="connection-types"
                                onChange={(e) => setConnectionType(e.target.value)}>
                                <SpecInput
                                    type="checkbox"
                                    id="G83/G98"
                                    name="connectionType"
                                    className="connection-type"
                                    onChange={() => { }}
                                    checked={connectionType === uk_g83_g98}
                                    disabled={disableCheckbox('london')}
                                    value="G83 / G98" />
                                <SpecLabel
                                    className={cx("connection-type-label", disableCheckbox('london') ? 'disabled' : '')}
                                    htmlFor="G83/G98">
                                    G83/G98
                                </SpecLabel>
                                <SpecInput
                                    type="checkbox"
                                    id="G59/G99"
                                    name="connectionType"
                                    className="connection-type"
                                    onChange={() => { }}
                                    checked={connectionType === uk_g59_g99}
                                    disabled={disableCheckbox('london')}
                                    value="G59 / G99" />
                                <SpecLabel
                                    className={cx("connection-type-label", disableCheckbox('london') ? 'disabled' : '')}
                                    htmlFor="G59/G99">
                                    G59/G99
                                </SpecLabel>
                                <SpecInput
                                    type="checkbox"
                                    id="AS4777"
                                    name="connectionType"
                                    className="connection-type"
                                    onChange={() => { }}
                                    checked={connectionType === as_4777}
                                    disabled={disableCheckbox('australia')}
                                    value="AS4777" />
                                <SpecLabel
                                    className={cx("connection-type-label",
                                        disableCheckbox('australia') ? 'disabled' : '')}
                                    htmlFor="AS4777">
                                    AS4777
                                </SpecLabel>
                            </div>
                            <Select
                                id="dno"
                                name="dno"
                                className="form-right"
                                styles={customStyles(dno.label, hasRequired)}
                                value={dno}
                                onChange={setDno}
                                placeholder="-- Select DNO --"
                                options={getOptions(getDnos())}
                            />
                        </div>
                    </div>
                    <div className="gsp-group">
                        <div className="form-left">GSP Group</div>
                        <Select
                            id="gspGroup"
                            name="gspGroup"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={gspGroup}
                            onChange={setGspGroup}
                            placeholder="-- Select GSP Group --"
                            options={getOptions(getGspGroups())}
                            isDisabled={timeZone.value !== _tz || parseInt(dno.value) < 10}
                        />
                    </div>
                    <div className="line-loss-factor">
                        <div className="form-left"><span className="required">* </span>Line Loss Factor</div>
                        <SpecInput
                            id="lineLossFactor"
                            type="text"
                            name="lineLossFactor"
                            className={cx("form-right", hasRequired && !lineLossFactor ? 'required-field' : '')}
                            value={lineLossFactor}
                            onChange={(e) => setLineLossFactor(e.target.value.toUpperCase())}
                            placeholder="Line loss factor class"
                            maxLength="3"
                            size="3" />
                    </div>
                    <div className="settlement-method">
                        <div className="form-left settlement">
                            <span className="required">* </span>Settlement Method
                        </div>
                        <div>
                            <div className="dr-dc-allowed">
                                <SpecInput
                                    type="checkbox"
                                    id="drAllowed"
                                    name="drAllowed"
                                    className="dr-allowed"
                                    onChange={() => setDrAllowed(!drAllowed)}
                                    checked={drAllowed} />
                                <SpecLabel
                                    className="dr-allowed-label"
                                    htmlFor="drAllowed">
                                    Demand Response allowed
                                </SpecLabel>
                                <SpecInput
                                    type="checkbox"
                                    id="dataCollection"
                                    name="dataCollection"
                                    className="data-collection-allowed"
                                    onChange={() => setDcAllowed(!dcAllowed)}
                                    checked={dcAllowed} />
                                <SpecLabel
                                    className="data-collection-allowed-label"
                                    htmlFor="dataCollection">
                                    Data collection allowed
                                </SpecLabel>
                            </div>
                            <Select
                                id="settlementMethod"
                                name="settlementMethod"
                                className="form-right"
                                styles={customStyles(settlementMethod.label, hasRequired)}
                                value={settlementMethod}
                                onChange={setSettlementMethod}
                                placeholder="-- Select settlement method --"
                                options={getOptions(SETTLEMENT_METHOD)}
                            />
                        </div>
                    </div>
                    <div className="rs485-port">
                        <div className="form-left"><span className="required">* </span>RS-485 Ports</div>
                        <div>
                            <Select
                                id="ports"
                                name="ports"
                                className="form-right"
                                styles={customStyles(ports.label, hasRequired)}
                                value={ports}
                                onChange={setPorts}
                                placeholder="-- Select RS-485 port(s) --"
                                options={getOptions(getPorts())}
                            />
                        </div>
                    </div>
                    <div className="metering-options">
                        <div className="form-left metering-label">Metering</div>
                        <div className="form-right metering-types">
                            <div className='metering-col1'>
                                <div className="3phase">
                                    <SpecInput
                                        type="checkbox"
                                        id="hasPhaseMetering"
                                        name="hasPhaseMetering"
                                        className="has-phase-metering"
                                        onChange={() => setHas3phase(!has3phase)}
                                        checked={has3phase} />
                                    <SpecLabel
                                        className="phase-metering-label"
                                        htmlFor="hasPhaseMetering">
                                        3-phase
                                    </SpecLabel>
                                </div>
                                <div className="grid">
                                    <SpecInput
                                        type="checkbox"
                                        id="hasGridMeter"
                                        name="hasGridMeter"
                                        className="has-grid-meter"
                                        onChange={() => setHasGridMeter(!hasGridMeter)}
                                        checked={hasGridMeter} />
                                    <SpecLabel
                                        className="grid-meter-label"
                                        htmlFor="hasGridMeter">
                                        Grid
                                    </SpecLabel>
                                </div>
                                <div className="invert-pv-wrapper">
                                    <SpecInput
                                        type="checkbox"
                                        id="invertPv"
                                        name="invertPv"
                                        className="invert-pv"
                                        onChange={() => setInvertPv(!invertPv)}
                                        checked={invertPv} />
                                    <SpecLabel className="invert-pv-label" htmlFor="invertPv">
                                        Reversed PV
                                    </SpecLabel>
                                </div>
                                <div className="invert-grid-wrapper">
                                    <SpecInput
                                        type="checkbox"
                                        id="invertGrid"
                                        name="invertGrid"
                                        className="invert-grid"
                                        onChange={() => setInvertGrid(!invertGrid)}
                                        checked={invertGrid} />
                                    <SpecLabel className="invert-grid-label" htmlFor="invertGrid">
                                        Reversed grid
                                    </SpecLabel>
                                </div>
                            </div>
                            <div className='metering-col2'>
                                <div className="invert-dru-inverter-wrapper">
                                    <SpecInput
                                        type="checkbox"
                                        id="invertDruInverter"
                                        name="invertDruInverter"
                                        className="invert-dru-inverter"
                                        onChange={() => setInvertDruInverter(!invertDruInverter)}
                                        checked={invertDruInverter} />
                                    <SpecLabel className="invert-dru-inverter-label" htmlFor="invertDruInverter">
                                        Reversed DRU inverter
                                    </SpecLabel>
                                </div>
                                <div className="invert-dru-grid-wrapper">
                                    <SpecInput
                                        type="checkbox"
                                        id="invertDruGrid"
                                        name="invertDruGrid"
                                        className="invert-dru-grid"
                                        onChange={() => setInvertDruGrid(!invertDruGrid)}
                                        checked={invertDruGrid} />
                                    <SpecLabel className="invert-dru-grid-label" htmlFor="invertDruGrid">
                                        Reversed DRU grid
                                    </SpecLabel>
                                </div>
                                <div className="invert-dru-hw-wrapper">
                                    <SpecInput
                                        type="checkbox"
                                        id="invertDruHw"
                                        name="invertDruHw"
                                        className="invert-dru-hw"
                                        onChange={() => setInvertDruHw(!invertDruHw)}
                                        checked={invertDruHw} />
                                    <SpecLabel className="invert-dru-hw-label" htmlFor="invertDruHw">
                                        Reversed DRU hot water
                                    </SpecLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="supply-phases">
                        <div className="form-left supply"><span className="required">* </span>Supply Phases</div>
                        <Select
                            id="supply-phases"
                            name="supply-phases"
                            className="form-right"
                            styles={customStyles(supplyPhases.label, hasRequired)}
                            value={supplyPhases}
                            onChange={setSupplyPhases}
                            placeholder="-- Select supply phases --"
                            options={getOptions(getSupplyPhase())}
                        />
                    </div>
                    <div className="import-limit">
                        <div className="form-left">Import Limit</div>
                        <SpecInput
                            id="importLimit"
                            type="number"
                            name="importLimit"
                            className="form-right"
                            value={importLimit}
                            onChange={(e) => setImportLimit(e.target.value)}
                            placeholder="Import limit in W" />
                    </div>
                    <div className="export-limit">
                        <div className="form-left">Export Limit</div>
                        <SpecInput
                            id="exportLimit"
                            type="number"
                            name="exportLimit"
                            className="form-right"
                            value={exportLimit}
                            onChange={(e) => setExportLimit(e.target.value)}
                            placeholder="Export limit in W" />
                    </div>
                    <div className="battery-system">
                        <div className="form-left">Battery System</div>
                        <Select
                            id="batterySystem"
                            name="batterySystem"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={batterySystem}
                            onChange={setBatterySystem}
                            placeholder="-- Select battery system --"
                            options={getOptions(fleetContext.batterySystems, true)}
                        />
                    </div>
                    <div className="dru-network">
                        <div className="form-left">DRU Connection</div>
                        <Select
                            id="druNetwork"
                            name="druNetwork"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={druNetwork}
                            onChange={setDruNetwork}
                            placeholder="-- Select DRU network type --"
                            options={getOptions(DRU_NETWORK_CONNECTION_TYPE)}
                        />
                    </div>
                    <div className="hot-water-tank">
                        <div className="form-left">Hot Water Tank</div>
                        <Select
                            id="hotWaterTank"
                            name="hotWaterTank"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={hotWaterTank}
                            onChange={setHotWaterTank}
                            placeholder="-- Select HW type --"
                            options={getOptions(fleetContext.hwTanks, true)}
                        />
                    </div>
                    <div className="battery-location">
                        <div className="form-left">Battery Location</div>
                        <Select
                            id="batteryLocation"
                            name="batteryLocation"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={batteryLocation}
                            onChange={setBatteryLocation}
                            placeholder="-- Select battery location --"
                            options={getOptions(BATTERY_LOCATION)}
                        />
                    </div>
                    <div className="charge-point">
                        <div className="form-left">EV Charge Point</div>
                        <SpecInput
                            id="chargePoint"
                            type="number"
                            name="chargePoint"
                            className="form-right"
                            value={chargePoint}
                            onChange={(e) => setChargePoint(e.target.value)}
                            placeholder="EV charge point ID"
                        />
                    </div>
                </div>
            )

        }
    }

    const assignment = () => {
        if (formType === assign) {
            const aggFacilities = []
            fleetContext.aggFacilities.forEach(af => {
                aggFacilities.push(af.name);
                if (!!af.constituents) af.constituents.forEach(fs => aggFacilities.push(fs.name));
            });

            return (
                <div className="facility-assignment">
                    <div className="operating-mode">
                        <div className="form-left">Operating Mode</div>
                        <Select
                            id="operatingMode"
                            name="operatingMode"
                            className="form-right"
                            styles={customStyles('', false)}
                            value={operatingMode}
                            onChange={setOperatingMode}
                            placeholder="-- Select operating mode --"
                            options={getOptions(OPERATING_MODE)}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="specification-form-container">
            <div className="specification-form-header">
                <div className="specification-form-title">Facility settings</div>
                <div className="required-info"><span className="required">* </span>indicates required fields</div>
            </div>
            <form className="specification-form" autoComplete="off">
                {formTypeSection()}
                {facilityName()}
                {specifications()}
                {assignment()}
                <div className="submit-spec">
                    <div className="message-container">
                        {!!facilityMessage &&
                            <>
                                <p className="info-message-spec">Facility name: {facilityMessage}</p>
                                <p className="info-message-spec">DRU ID: {dru.id}</p>
                                <p className="info-message-spec">DRU Password: {dru.password}</p>
                            </>
                        }
                        {!!specificationMessage && formType === edit
                            && <p className="info-message-spec">{specificationMessage}</p>}
                        {!!operatingModeMessage && formType === assign
                            && <p className="info-message-spec">{operatingModeMessage}</p>}
                        {!!assignFacilityMessage && formType === assign
                            && <p className="info-message-spec">{assignFacilityMessage}</p>}
                        {!!validationError && <p className="error-message-spec">{validationError}</p>}
                        {!!error && <p className="error-message-spec">{error}.</p>}
                    </div>
                    <div>
                        <Button className="configure-btn reset" type="button" onClick={(e) => handleReset(e)}>
                            Reset
                        </Button>
                        <Button className="configure-btn submit" type="button" onClick={(e) => handleSubmit(e)}>
                            Submit
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}


export default SpecificationFrom;
