/* Copyright Levelise Ltd 2021-2025 */
import { useState, useEffect, useRef, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import FacilityContext from '../../../contexts/FacilityContext';
import {
	DEMAND_RESPONSE_SECOND,
	DEMAND_RESPONSE_MINUTE,
	DEMAND_RESPONSE,
	TIME_FRAMES,
	timestampMsec,
	timestampSec,
	timestampDay,
	resolutions,
} from '../../../utils/constants';
import {
	CONTRACT_ANNOTATION,
	getValue,
	getDiffValues,
	availableCharge,
	availableDischarge,
	dispatchedPower,
	dispatchedImport,
	dispatchedExport,
	maxCharge,
	maxDischarge,
	gridFrequency,
	getTimeFormat,
	dataset,
	options,
	xAxis,
	facilityChartTitles,
	tooltipTitleCallbacks,
} from '../../../utils/chart';
import { getUnixTime } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

const x = { type: 'time' };
const x1 = null;
const y = {
	stacked: false,
	ticks: {},
	label: 'Energy (kWh)',
	gridLines: { drawOnChartArea: true },
};
const y1 = {
	stacked: false,
	ticks: { suggestedMin: 49.5, suggestedMax: 50.5, steps: 10, stepValue: 0.1 },
	label: 'Frequency (Hz)',
	gridLines: { drawOnChartArea: false },
};
const legendLabels = {
	font: { size: 10 },
	color: 'black',
	usePointStyle: true,
	boxWidth: 4,
	boxHeight: 4,
	padding: 8,
	filter: function (item, chart) {
		const idx = item.datasetIndex;
		const dataset = chart.datasets[idx];
		return !dataset.hidden;
	},
};

const actualToForecastDatasetIndexMap = {
	0: 7,
	1: 8,
};

const forecastToActualDatasetIndexMap = {
	7: 0,
	8: 1,
};

const DemandResponseChart = ({
	height,
	fmData,
	handleFmData,
	data,
	showDistinct,
	timezone,
	selectedTimezone,
	showForecast,
}) => {
	const facilityDrRef = useRef();
	const context = useContext(FacilityContext);
	const dataSetLabels = {
		availableCharge: availableCharge.type,
		availableDischarge: availableDischarge.type,
		dispatchedPower: dispatchedPower.type,
		dispatchedImport: dispatchedImport.type,
		dispatchedExport: dispatchedExport.type,
		maxCharge: maxCharge.type,
		maxDischarge: maxDischarge.type,
		gridFrequency: gridFrequency.type,
	};
	const [chart, setChart] = useState({
		data: {
			labels: [],
			datasets: [
				dataset(
					'line',
					availableCharge.type,
					availableCharge.backgroundColor,
					availableCharge.borderColor,
					'y'
				),
				dataset(
					'line',
					availableDischarge.type,
					availableDischarge.backgroundColor,
					availableDischarge.borderColor,
					'y'
				),
				dataset(
					'line',
					dispatchedPower.type,
					dispatchedPower.backgroundColor,
					dispatchedPower.borderColor,
					'y'
				),
				dataset(
					'line',
					dispatchedImport.type,
					dispatchedImport.backgroundColor,
					dispatchedImport.borderColor,
					'y'
				),
				dataset(
					'line',
					dispatchedExport.type,
					dispatchedExport.backgroundColor,
					dispatchedExport.borderColor,
					'y'
				),
				dataset('line', maxCharge.type, maxCharge.backgroundColor, maxCharge.borderColor, 'y'),
				dataset('line', maxDischarge.type, maxDischarge.backgroundColor, maxDischarge.borderColor, 'y'),
				dataset(
					'line',
					availableCharge.forecastType,
					availableCharge.forecastColor,
					availableCharge.forecastColor,
					'y'
				),
				dataset(
					'line',
					availableDischarge.forecastType,
					availableDischarge.forecastColor,
					availableDischarge.forecastColor,
					'y'
				),
				dataset('line', gridFrequency.type, gridFrequency.backgroundColor, gridFrequency.borderColor, 'y1'),
			],
		},
		options: options('', legendLabels, x, x1, y, y1),
	});

	const resetState = () => {
		setChart({
			data: {
				labels: [],
				datasets: [
					dataset(
						'line',
						availableCharge.type,
						availableCharge.backgroundColor,
						availableCharge.borderColor,
						'y'
					),
					dataset(
						'line',
						availableDischarge.type,
						availableDischarge.backgroundColor,
						availableDischarge.borderColor,
						'y'
					),
					dataset(
						'line',
						dispatchedPower.type,
						dispatchedPower.backgroundColor,
						dispatchedPower.borderColor,
						'y'
					),
					dataset(
						'line',
						dispatchedImport.type,
						dispatchedImport.backgroundColor,
						dispatchedImport.borderColor,
						'y'
					),
					dataset(
						'line',
						dispatchedExport.type,
						dispatchedExport.backgroundColor,
						dispatchedExport.borderColor,
						'y'
					),
					dataset('line', maxCharge.type, maxCharge.backgroundColor, maxCharge.borderColor, 'y'),
					dataset('line', maxDischarge.type, maxDischarge.backgroundColor, maxDischarge.borderColor, 'y'),
					dataset(
						'line',
						availableCharge.forecastType,
						availableCharge.forecastColor,
						availableCharge.forecastColor,
						'y'
					),
					dataset(
						'line',
						availableDischarge.forecastType,
						availableDischarge.forecastColor,
						availableDischarge.forecastColor,
						'y'
					),
					dataset('line', gridFrequency.type, gridFrequency.backgroundColor, gridFrequency.borderColor, 'y1'),
				],
			},
			options: options('', legendLabels, x, x1, y, y1),
		});
	}

	const handleDataOnChange = (data) => {
		let resolution = data.resolution;
		if (data.timeFrame !== TIME_FRAMES.select) {
			switch (data.timeFrame) {
				case TIME_FRAMES.fifteen_minutes:
					resolution = resolutions.second;
					break;
				case TIME_FRAMES.one_hour:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.six_hours:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.twenty_four_hours:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.one_week:
					resolution = resolutions.half_hour;
					break;
				case TIME_FRAMES.twenty_one_days:
					resolution = resolutions.half_hour;
					break;
				case TIME_FRAMES.three_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.twelve_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.thirty_six_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.all:
					resolution = resolutions.week;
					break;
				default:
					break;
			}
		}

		switch (resolution) {
			case resolutions.week:
			case resolutions.day:
				populateChartByDay(data.dailyReports, resolution);
				break;
			case resolutions.half_hour:
				populateChartByHalfHour(data.halfHourlyReports, resolution);
				break;
			case resolutions.minute:
				populateChartByMinute(data.minutelyReports, resolution, data.updated, data.forecasts);
				break;
			case resolutions.second:
				populateChartBySecond(data.bySecondReports, resolution, data.updated);
				break;
			default:
				break;
		}
	};

	const populateChartByDay = (reports, resolution) => {
		const { availableCharge, availableDischarge, dispatchedImport, dispatchedExport, gridFrequency } =
			dataSetLabels;
		let labels = [];
		let datasets = chart.data.datasets.map((d) => {
			d.data = [];
			return d;
		});

		if (!reports.length) {
			resetState();
			return;
		}
		const start = reports[0][timestampDay];
		const end = reports[reports.length - 1][timestampDay];
		const increment = resolution === resolutions.week ? 7 : 1;
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += increment) {
			if (timestamp === reports[i][timestampDay]) {
				const dispatched = getDiffValues(
					reports[i][DEMAND_RESPONSE[dispatchedImport]],
					reports[i][DEMAND_RESPONSE[dispatchedExport]]
				);

				datasets[0].data.push(getValue(reports[i][DEMAND_RESPONSE[availableCharge]])); // available charge
				datasets[1].data.push(getValue(reports[i][DEMAND_RESPONSE[availableDischarge]], true)); // available discharge
				datasets[2].data.push(dispatched); // import - export dispatched
				datasets[3].data.push(getValue(reports[i][DEMAND_RESPONSE[dispatchedImport]])); // dispatched import
				datasets[4].data.push(getValue(reports[i][DEMAND_RESPONSE[dispatchedExport]], true)); // dispatched export
				datasets[9].data.push(getValue(reports[i][DEMAND_RESPONSE[gridFrequency]])); // grid frequency

				i++;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				datasets[3].data.push(null);
				datasets[4].data.push(null);
				datasets[9].data.push(null);
			}

			labels.push(timestamp * 86400 * 1000);
		}

		datasets[2].hidden = showDistinct;
		datasets[3].hidden = !showDistinct;
		datasets[4].hidden = !showDistinct;
		datasets[5].hidden = true;
		datasets[6].hidden = true;
		datasets[9].hidden = true;
		datasets[7].hidden = true;
		datasets[8].hidden = true;

		const label = `Energy (kWh)`;
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, [], false);
	};

	const populateChartByHalfHour = (reports, resolution) => {
		const { availableCharge, availableDischarge, dispatchedImport, dispatchedExport, gridFrequency } =
			dataSetLabels;
		let labels = [];
		let datasets = chart.data.datasets.map((d) => {
			d.data = [];
			return d;
		});

		if (!reports.length) {
			resetState();
			return;
		}

		const start = reports[0][timestampSec];
		const end = reports[reports.length - 1][timestampSec];
		const batterySystemSpecification = context?.facility?.batterySystem ? context.facility.batterySystem : null;
		let maxChargeKwh = batterySystemSpecification ? batterySystemSpecification.maxChargeW / 2000 : 0;
		let maxDischargeKwh = batterySystemSpecification ? batterySystemSpecification.maxDischargeW / 2000 : 0;
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += 1800) {
			if (timestamp === parseInt(reports[i][timestampSec])) {
				const dispatched = getDiffValues(
					reports[i][DEMAND_RESPONSE[dispatchedImport]],
					reports[i][DEMAND_RESPONSE[dispatchedExport]]
				);

				datasets[0].data.push(getValue(reports[i][DEMAND_RESPONSE[availableCharge]])); // available charge
				datasets[1].data.push(getValue(reports[i][DEMAND_RESPONSE[availableDischarge]], true)); // available discharge
				datasets[2].data.push(dispatched); // import - export dispatched
				datasets[3].data.push(getValue(reports[i][DEMAND_RESPONSE[dispatchedImport]])); // dispatched import
				datasets[4].data.push(getValue(reports[i][DEMAND_RESPONSE[dispatchedExport]], true)); // dispatched export
				datasets[5].data.push(maxChargeKwh); // max charge
				datasets[6].data.push(-maxDischargeKwh); // max discharge
				datasets[9].data.push(getValue(reports[i][DEMAND_RESPONSE[gridFrequency]])); // grid frequency

				i++;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				datasets[3].data.push(null);
				datasets[4].data.push(null);
				datasets[5].data.push(null);
				datasets[6].data.push(null);
				datasets[9].data.push(null);
			}

			labels.push(timestamp * 1000);
		}

		datasets[2].hidden = showDistinct;
		datasets[3].hidden = !showDistinct;
		datasets[4].hidden = !showDistinct;
		datasets[5].hidden = false;
		datasets[6].hidden = false;
		datasets[9].hidden = false;

		datasets[7].hidden = true;
		datasets[8].hidden = true;

		const label = `Energy (kWh)`;
		const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, true);
	};

	const populateChartByMinute = (reports, resolution, update = false, forecasts = null) => {
		const { availableCharge, availableDischarge, dispatchedImport, dispatchedExport, gridFrequency } =
			dataSetLabels;
		let labels = update ? [...chart.data.labels] : [];
		let datasets = update
			? [...chart.data.datasets]
			: chart.data.datasets.map((d) => {
					d.data = [];
					return d;
			  });

		let end = 0;
		if (reports && reports.length) {
			const start = reports[0][timestampSec];
			end = reports[reports.length - 1][timestampSec];
			const batterySystemSpecification = context?.facility?.batterySystem ? context.facility.batterySystem : null;
			let maxCharge = batterySystemSpecification ? batterySystemSpecification.maxChargeW : 0;
			let maxDischarge = batterySystemSpecification ? batterySystemSpecification.maxDischargeW : 0;
			let i = 0;
			for (let timestamp = start; timestamp <= end; timestamp += 60) {
				if (update) {
					labels.shift();
					datasets[0].data.shift();
					datasets[1].data.shift();
					datasets[2].data.shift();
					datasets[3].data.shift();
					datasets[4].data.shift();
					datasets[5].data.shift();
					datasets[6].data.shift();
					datasets[9].data.shift();
				}

				if (timestamp === parseInt(reports[i][timestampSec])) {
					const dispatched = getDiffValues(
						reports[i][DEMAND_RESPONSE_MINUTE[dispatchedImport]],
						reports[i][DEMAND_RESPONSE_MINUTE[dispatchedExport]]
					);

					datasets[0].data.push(getValue(reports[i][DEMAND_RESPONSE_MINUTE[availableCharge]])); // available charge
					datasets[1].data.push(getValue(reports[i][DEMAND_RESPONSE_MINUTE[availableDischarge]], true)); // available discharge
					datasets[2].data.push(dispatched); // import - export dispatched
					datasets[3].data.push(getValue(reports[i][DEMAND_RESPONSE_MINUTE[dispatchedImport]])); // dispatched import
					datasets[4].data.push(getValue(reports[i][DEMAND_RESPONSE_MINUTE[dispatchedExport]], true)); // dispatched export
					datasets[5].data.push(maxCharge); // max charge
					datasets[6].data.push(-maxDischarge); // max discharge
					datasets[9].data.push(getValue(reports[i][DEMAND_RESPONSE_MINUTE[gridFrequency]])); // grid frequency

					i++;
				} else {
					datasets[0].data.push(null);
					datasets[1].data.push(null);
					datasets[2].data.push(null);
					datasets[3].data.push(null);
					datasets[4].data.push(null);
					datasets[5].data.push(null);
					datasets[6].data.push(null);
					datasets[9].data.push(null);
				}

				labels.push(timestamp * 1000);
			}
		}

		if (forecasts && forecasts.length > 0 && data.timeFrame === TIME_FRAMES.twenty_four_hours && showForecast) {
			if (!reports?.length) {
				end = forecasts[forecasts.length - 1][timestampSec];
			}

			if (reports.length <= forecasts.length) {
				labels = [];
			}

			const nowZonedTimestamp = getUnixTime(fromZonedTime(new Date(), timezone));
			const endForForecasts = nowZonedTimestamp + 86401; // next 24 hours timestamp + 1sec

			let j = 0;
			let lastTimestamp = 0;
			while (j < forecasts.length && lastTimestamp < endForForecasts) {
				const forecast = forecasts[j];

				datasets[7].data.push(getValue(forecast?.drAvailableHighW)); // Available high forecast
				datasets[8].data.push(getValue(forecast?.drAvailableLowW, true)); // Available low forecast

				j++;
				lastTimestamp = forecast[timestampSec];
				labels.push(forecast[timestampSec] * 1000);
			}
		} else {
			datasets[7].data = [];
			datasets[8].data = [];
		}

		datasets[2].hidden = showDistinct;
		datasets[3].hidden = !showDistinct;
		datasets[4].hidden = !showDistinct;
		datasets[5].hidden = false;
		datasets[6].hidden = false;
		datasets[9].hidden = false;

		datasets[7].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast;
		datasets[8].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast;

		const label = `Power (W)`;
		const annotation = CONTRACT_ANNOTATION(end, timezone);
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, true);
	};

	const populateChartBySecond = (reports, resolution, update = false) => {
		let labels = update ? [...chart.data.labels] : [];
		let datasets = update
			? [...chart.data.datasets]
			: chart.data.datasets.map((d) => {
					d.data = [];
					return d;
			  });
		const { availableCharge, availableDischarge, dispatchedImport, dispatchedExport, gridFrequency } =
			dataSetLabels;

		if (!reports.length) {
			if (!update) {
				resetState();
			}
			return;
		}
		const start = reports[0][timestampMsec];
		const end = reports[reports.length - 1][timestampMsec];
		const batterySystemSpecification = context?.facility?.batterySystem ? context.facility.batterySystem : null;
		let maxCharge = batterySystemSpecification ? batterySystemSpecification.maxChargeW : 0;
		let maxDischarge = batterySystemSpecification ? batterySystemSpecification.maxDischargeW : 0;
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += 1000) {
			if (parseInt(timestamp / 1000) === parseInt(reports[i][timestampMsec] / 1000)) {
				const dispatched = getDiffValues(
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedImport]],
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedExport]]
				);

				datasets[0].data.push(getValue(reports[i][DEMAND_RESPONSE_SECOND[availableCharge]])); // available charge
				datasets[1].data.push(getValue(reports[i][DEMAND_RESPONSE_SECOND[availableDischarge]], true)); // available discharge
				datasets[2].data.push(dispatched); // import - export dispatched
				datasets[3].data.push(getValue(reports[i][DEMAND_RESPONSE_SECOND[dispatchedImport]])); // dispatched import
				datasets[4].data.push(getValue(reports[i][DEMAND_RESPONSE_SECOND[dispatchedExport]], true)); // dispatched export
				datasets[5].data.push(maxCharge); // max charge
				datasets[6].data.push(-maxDischarge); // max discharge
				datasets[9].data.push(getValue(reports[i][DEMAND_RESPONSE_SECOND[gridFrequency]])); // grid frequency

				i++;
			} else if (parseInt(timestamp / 1000) - 1 === parseInt(reports[i][timestampMsec] / 1000)) {
				const dispatched = getDiffValues(
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedImport]],
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedExport]]
				);

				datasets[0].data[datasets[0].data.length - 1] = getValue(
					reports[i][DEMAND_RESPONSE_SECOND[availableCharge]]
				); // available charge
				datasets[1].data[datasets[1].data.length - 1] = getValue(
					reports[i][DEMAND_RESPONSE_SECOND[availableDischarge]],
					true
				); // available discharge
				datasets[2].data[datasets[2].data.length - 1] = dispatched; // import - export dispatched
				datasets[3].data[datasets[3].data.length - 1] = getValue(
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedImport]]
				); // dispatched import
				datasets[4].data[datasets[4].data.length - 1] = getValue(
					reports[i][DEMAND_RESPONSE_SECOND[dispatchedExport]],
					true
				); // dispatched export
				datasets[5].data[datasets[5].data.length - 1] = maxCharge; // max charge
				datasets[6].data[datasets[6].data.length - 1] = -maxDischarge; // max discharge
				datasets[9].data[datasets[9].data.length - 1] = getValue(
					reports[i][DEMAND_RESPONSE_SECOND[gridFrequency]]
				); // grid frequency

				timestamp -= 1000;
				i++;
				continue;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				datasets[3].data.push(null);
				datasets[4].data.push(null);
				datasets[5].data.push(null);
				datasets[6].data.push(null);
				datasets[9].data.push(null);
			}

			if (update) {
				labels.shift();
				datasets[0].data.shift();
				datasets[1].data.shift();
				datasets[2].data.shift();
				datasets[3].data.shift();
				datasets[4].data.shift();
				datasets[5].data.shift();
				datasets[6].data.shift();
				datasets[9].data.shift();
			}

			labels.push(timestamp);
		}

		datasets[2].hidden = showDistinct;
		datasets[3].hidden = !showDistinct;
		datasets[4].hidden = !showDistinct;
		datasets[5].hidden = false;
		datasets[6].hidden = false;
		datasets[9].hidden = false;

		datasets[7].hidden = true;
		datasets[8].hidden = true;

		const label = `Power (W)`;
		const annotation = CONTRACT_ANNOTATION(parseInt(end / 1000), timezone);
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, true);
	};

	const getTooltipLabelAndValue = (tooltipItem) => {
		const { datasetIndex, dataIndex, chart } = tooltipItem;
		const datasets = chart.data.datasets;

		// for forecast datasets, return empty string if actual value exists else return label with value
		if (datasetIndex >= 7 && datasetIndex <= 8) {
			if (
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== null &&
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== undefined
			) {
				return '';
			}

			if (
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] === null ||
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] === undefined
			) {
				return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]}`;
			}
		}

		// for actual data check if forecast should be displayed and forecast data exists and display it next to the actual data
		if (
			showForecast &&
			datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== null &&
			datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== undefined &&
			!datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.hidden
		) {
			return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]} (Forecast: ${
				datasets[actualToForecastDatasetIndexMap[datasetIndex]].data[dataIndex]
			})`;
		}

		return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]}`;
	};

	const getTimeFrame = () => {
		let timeFrame = data.timeFrame;
		if (timeFrame === TIME_FRAMES.select) {
			const { dailyReports, halfHourlyReports } = data;
			const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } =
				TIME_FRAMES;

			switch (data.resolution) {
				case resolutions.day: {
					let dailyReportLen = dailyReports.length;
					timeFrame =
						dailyReportLen <= 60 ? two_months : dailyReportLen <= 366 ? twelve_months : thirty_six_months;
					break;
				}
				case resolutions.half_hour: {
					let halfHourlyReportLen = halfHourlyReports.length;
					timeFrame =
						halfHourlyReportLen <= 50
							? twenty_four_hours
							: halfHourlyReportLen <= 336
							? one_week
							: fifteen_days;
					break;
				}
				case resolutions.minute:
					timeFrame = twenty_four_hours;
					break;
				default:
					break;
			}
		}

		if (fmData.hasFmData) {
			return TIME_FRAMES.fifteen_minutes;
		}

		return timeFrame;
	};

	const handleSetChart = (labels, datasets, label, time, annotation, showFrequency) => {
		const maxAbs = Math.abs(Math.max(...datasets.map((d, i) => (i !== 7 ? Math.max(...d.data) : 0))));
		const minAbs = Math.abs(Math.min(...datasets.map((d, i) => (i !== 7 ? Math.min(...d.data) : 0))));
		const max = maxAbs > minAbs ? maxAbs : minAbs;
		const timeFrame = getTimeFrame();

		let range = max > 40 ? parseInt((max + 5) / 5) * 5 : max;
		if (range === 0) range = 1;
		else range = max < 40 && range % 1 < 0.5 ? Math.floor(range) + 0.5 : Math.ceil(range * 1.05);

		const update = {
			data: { labels: labels, datasets: datasets },
			options: {
				...chart.options,
				layout: { padding: { right: showFrequency ? 0 : 12, top: -8 } },
				plugins: {
					...chart.options.plugins,
					annotation: { annotations: annotation },
					tooltip: {
						...chart.options.plugins.tooltip,
						callbacks: { ...tooltipTitleCallbacks(selectedTimezone), label: getTooltipLabelAndValue },
					},
				},
				scales: {
					...chart.options.scales,
					y: {
						...chart.options.scales.y,
						min: -range,
						max: range,
						title: { ...chart.options.scales.y.title, display: true, text: label },
					},
					y1: { ...chart.options.scales.y1, display: showFrequency, min: 49.5, max: 50.5 },
					x: xAxis(timeFrame, time, selectedTimezone),
				},
				onClick: (event, items, chart) => {
					if (items?.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
				},
			},
		};

		update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes;
		update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes;
		setChart(update);
	};

	useEffect(() => {
		if (fmData.hasFmData && !!fmData.bySecondReports.length) {
			populateChartBySecond(fmData.bySecondReports, resolutions.second);
		}
	}, [fmData.bySecondReports, selectedTimezone]);

	useEffect(() => {
		if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
			handleDataOnChange(data);
		}
	}, [data.resolution, data.minutelyReports, data.halfHourlyReports, data.dailyReports]);

	useEffect(() => {
		if (data.updated) {
			handleDataOnChange(data);
		}
	}, [data.bySecondReports, data.minutelyReports]);

	useEffect(() => {
		if (!fmData.hasFmData && !data.updated) {
			handleDataOnChange(data);
		}
	}, [data.timeFrame, fmData.hasFmData, data.minutelyReports, selectedTimezone]);

	return (
		<div className="demand-response-chart" style={{ height }}>
			<Line id={facilityChartTitles.dr[1]} ref={facilityDrRef} data={chart.data} options={chart.options} />
		</div>
	);
};

export default DemandResponseChart;
